@font-face {
  font-family: 'pirateText';
  src: url('./fonts/AlikeAngular-Regular.ttf');
}

@font-face {
  font-family: 'pirateHeader';
  src: url('./fonts/IMFellEnglish-Italic.ttf');
}

body {
  background: url('./assets/pirateBackground.jpg');
  background-size: cover;
  margin: 0;
  font-family: -apple-system, pirateText, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pirateHeader {
  font-family: pirateHeader;
  color: #f9ea35;
  text-shadow: 2px 2px 4px #000000;
}

.piratePrice {
  font-size: '72px';
  color: 'white';
}

.hotelCard {
  background: url('./assets/priceBox.png');
  background-size: cover;
  border: none;
  max-width: 450px;
  color: #f9ea35;
  text-shadow: 2px 2px 4px #000000;
}

.subtleInfoBackground {
  background-color: rgba(255, 255, 255, 0.3);
  max-width: 780px;
  border-radius: 7px;
  padding: 5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
